import React from 'react';
import {TechseeBaseComponent} from '../../_shared/techsee-base-component';
import {IDesignable} from '../../_shared/reusable-props';

export interface IIcon extends IDesignable {
    iconName: string;
    style?: any;
}

export class Icon extends TechseeBaseComponent<IIcon> {
    renderInternal(): JSX.Element {
        this.addCssClass(this.props.className);
        this.addCssClass(`icon icon-${this.props.iconName}`);

        return <i style={{...this.props.style}} className={this.renderCssClasses()}></i>;
    }
}
